/* eslint-disable react-hooks/rules-of-hooks,react/display-name */
import { useRef } from 'react';
import { useZusModalStore, ModalWrapper } from '@/helpers/modal.js';

const DemoModal = (Component) => function ({ videoId, isPopin, ...props }) {
  const { setModal1, setModal2 } = useZusModalStore.getState();
  const divRef = useRef();
  const embedUrl = `https://demo.arcade.software/${videoId}`;
  async function onClick() {
    const content = (
      <ModalWrapper onClose={() => (isPopin ? setModal2(null) : setModal1(null))} delay={50}>
        <div className="flex items-center justify-center w-screen h-screen overflow-hidden bg-white" ref={divRef}>
          <div className="rounded-2xl overflow-hidden h-[800px] w-[1100px]">
            <iframe
              src={`${embedUrl}?embed`}
              frameBorder="0"
              loading="lazy"
              allowFullScreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              style={{ width: '100%', height: '100%' }}
              title="Démo réservation - OfficeRiders"
            />
          </div>
        </div>
      </ModalWrapper>
    );
    return setModal1(content);
  }

  return (
    <Component openModalDemo={() => onClick()} {...props} />
  );
};

export default DemoModal;
