import { useState, useEffect, Fragment } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Markdown, lang, useTranslation } from '@/helpers/translate';
import { useZusHomeStore } from '@/helpers/context';
import { getImageOutside } from '@/helpers/common';
import { getPathByLang } from '@/helpers/router';
import DemoModal from '@/core/demos';

function ZoneServices() {
  const { t } = useTranslation();
  const { ua, extWebpJpg: extension } = useZusHomeStore.getState();

  const categories = [
    {
      slug: 'contact',
      details: ['staff', 'stuff', 'ambience', 'art', 'furniture'],
    },
    {
      slug: 'instant',
      details: ['breakfast', 'lunch', 'dinner', 'dinner_cocktails', 'drinks'],
    },
    {
      slug: 'price',
      details: ['teambuilding', 'ambiance', 'healthy', 'sport', 'accessibility'],
    },
  ];

  const tabCates = categories.map((cate) => cate.slug);

  const [category, setCategory] = useState('instant');
  const [run, setRun] = useState(true);
  const langCurrent = lang();

  useEffect(() => {
    let timer;
    if (ua?.isDesktop && run) {
      timer = setTimeout(() => {
        if (!run) return;
        let index = tabCates.findIndex((e) => e === category);

        if (index >= tabCates.length - 1) index = -1;
        setCategory(tabCates[index + 1]);
      }, 3000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [ua?.isDesktop, run, category, tabCates]);

  const VideoDemo = DemoModal(({ openModalDemo }) => (
    <button className="relative flex items-center px-4 py-4 overflow-hidden bg-white border border-white shadow-button hover:shadow-none hover:border-white-dark2" onClick={openModalDemo}>
      <div className="absolute -left-[8px] bg-gradient-to-r from-[#4C8AFF] to-blue px-[19px] py-[19px] rounded-full">
        <svg className="relative left-[2px]" width="27.5" height="27.5" xmlns="http://www.w3.org/2000/svg">
          <g stroke="#FFF" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <path d="M26.75 13.75a13 13 0 01-26 0c0-7.18 5.82-13 13-13s13 5.82 13 13h0z" />
            <path d="M19.75 13.75a.539.539 0 01-.274.47l-7.937 4.462a.526.526 0 01-.527-.006.539.539 0 01-.262-.464V9.287a.53.53 0 01.789-.468l7.937 4.46a.539.539 0 01.274.47z" />
          </g>
        </svg>
      </div>
      <span className="text-lg leading-none pl-14">{t('home:ctademo')}</span>
    </button>
  ));

  return (
    <section className="relative flex lg:-top-48" onMouseEnter={() => setRun(false)} onMouseLeave={() => setRun(true)}>
      <div className="container flex flex-col mx-auto md:pt-0 lg:px-20 xl:px-0">
        <div className="flex flex-col justify-center h-full px-8 pt-6 lg:px-0 md:pt-0 md:flex-row">
          <div className="w-full py-0 mr-8 md:w-40v lg:mr-0 lg:w-50v xl:w-40v 2xl:w-35v lg:px-8">
            <div className="relative flex justify-center h-full">
              {categories.map((c) => (
                <div key={`zoneservicescategoriesdiv${c.slug}`} className={`${c.slug === category ? '' : 'hidden'} rounded-xl overflow-hidden w-full h-full relative`}>
                  <Image className="object-contain" alt="Services sur OfficeRiders" sizes="(max-width: 480px) 200px, (max-width: 1680px) 400px, (min-width: 1680px) 400px, 100vw" fill src={`https://officeriders-imager.s3.amazonaws.com/${getImageOutside(`home-${c.slug}.${extension}`, ua)}`} />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full py-6 md:w-1/2 lg:w-2/3 xl:w-1/3">
            <h3 className="font-medium text-2xl lg:text-[28px] max-w-xl lg:leading-9 text-black">
              {t('home:services.title')}
              {' '}
              <Link className="cursor-pointer underline-hand-2 text-blue title_plus" href={getPathByLang('services', langCurrent)}>{t('home:services.title_2')}</Link>
            </h3>
            {categories.map((c) => (
              <Fragment key={`zoneservicesFservices${c.slug}`}>
                <div key={`zoneservicesFservicesdiv${c.slug}`} className="flex pt-5 cursor-pointer lg:pt-8 group" onClick={() => setCategory(c.slug)}>
                  <div className={c.slug !== category ? 'w-[2px] mr-5 bg-white-dark4 group-hover:bg-dark rounded-full transition ' : 'w-[2px] mr-5 bg-black rounded-full transition'} />
                  <div>
                    <div className="max-w-[220px]">
                      <Markdown className="text-lg font-medium leading-none lg:text-xl lg:leading-6">{t(`home:services_title.${c.slug}`)}</Markdown>
                    </div>
                    <p className="max-w-md pt-1 text-sm lg:text-base text-balancez text-dark">{t(`home:services_title.${c.slug}_p`)}</p>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
        <div className="relative flex justify-center mt-0 lg:mt-10">
          <div className="relative hidden w-full max-w-5xl md:flex h-44 gradient-elipse ">
            <div className="absolute bottom-0 w-full h-20 bg-white border-t border-white-dark3 z-5" />
          </div>
          <div className="hidden font-medium md:block z-5 md:absolute my-4 lg:my-0 md:top-[55px] lg:top-[73px]">
            <VideoDemo videoId="0OaPurNJS6lU1qX4cyr7" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ZoneServices;
